Mosaic.addPlugins([require('/home/horecamage2/public_html/localmodules/gooshoreca-pwa/packages/@scandiweb/gtm/src/plugin/events/wishlistDispatcher.plugin.js')]);
import { Dispatch } from 'redux';

import AmMWishlistQuery from 'Query/AmMWishlist.query';
import WishlistQuery from 'Query/Wishlist.query';
// import { Wishlist } from 'Query/Wishlist.type';
import { updateAmMWishlist } from 'Store/AmMWishlist/AmMWishlist.action';
import { AmMWishListQueryOptions } from 'Store/AmMWishlist/AmMWishlist.type';
import CartDispatcher from 'Store/Cart/Cart.dispatcher';
import { showNotification } from 'Store/Notification/Notification.action';
import { NotificationType, ShowNotificationAction } from 'Store/Notification/Notification.type';
import {
    clearWishlist,
    removeItemFromWishlist,
    updateAllProductsInWishlist,
    updateIsLoading,
} from 'Store/Wishlist/Wishlist.action';
import { NetworkError } from 'Type/Common.type';
import { GQLWishlistItemInput, GQLWishlistItemUpdateInput } from 'Type/Graphql.type';
import { isSignedIn } from 'Util/Auth/IsSignedIn';
import { getAuthorizationToken } from 'Util/Auth/Token';
import BrowserDatabase from 'Util/BrowserDatabase';
import { isAmWishlistEnabled } from 'Util/MultipleWishlist';
import { getErrorMessage } from 'Util/Request/Error';
import { fetchMutation } from 'Util/Request/Mutation';
import { fetchQuery } from 'Util/Request/Query';
import { getStoreState } from 'Util/Store';

import { ClearWishlistAction } from './Wishlist.type';

/**
 * Get wishlist setting.
 * @namespace Store/Wishlist/Dispatcher/isWishlistEnabled */
export const isWishlistEnabled = (): boolean => {
    const state = getStoreState();
    const {
        wishlist_general_active = false,
    } = state.ConfigReducer || {};

    return wishlist_general_active;
};

/**
 * Product Wishlist Dispatcher
 * @class WishlistDispatcher
 * @namespace Store/Wishlist/Dispatcher
 */
export class WishlistDispatcher {
    updateInitialWishlistData(dispatch: Dispatch): void {
        if (!isSignedIn() || !isWishlistEnabled()) {
            dispatch(updateAllProductsInWishlist({}));
        }
    }

    _syncWishlistWithBE(dispatch: Dispatch, wishlistId?: string): Promise<void> {
        if (!wishlistId) {
            dispatch(updateIsLoading(false));

            return Promise.reject();
        }

        // Need to get current wishlist from BE, update wishlist
        return fetchQuery(AmMWishlistQuery.getWishlist('', Number(wishlistId))).then(
            /** @namespace Store/Wishlist/Dispatcher/WishlistDispatcher/_syncWishlistWithBE/fetchQuery/then */
            ({ wishlist }) => {
                if (!getAuthorizationToken()) {
                    return;
                }

                if (wishlist) {
                    dispatch(updateAmMWishlist(wishlist));
                } else {
                    dispatch(updateIsLoading(false));
                }
            },
            /** @namespace Store/Wishlist/Dispatcher/WishlistDispatcher/_syncWishlistWithBE/fetchQuery/then/catch */
            () => {
                dispatch(updateIsLoading(false));
            },
        );
    }

    async addItemToWishlist(
        dispatch: Dispatch,
        options: { items: GQLWishlistItemInput[]; wishlistId: string },
    ): Promise<void> {
        if (!isSignedIn()) {
            return;
        }

        try {
            const { items = [], wishlistId = '' } = options;

            dispatch(updateIsLoading(true));
            const {
                addProductsToWishlist: { user_errors },
            } = await fetchMutation(WishlistQuery.addProductsToWishlist(wishlistId, items));

            if (user_errors.length > 0) {
                user_errors.map(({ message }: NetworkError) => dispatch(
                    showNotification(
                        NotificationType.ERROR,
                        __('We can`t add the item to Wishlist right now: %s', message).toString(),
                    ),
                ));
            } else {
                // dispatch(showNotification(NotificationType.SUCCESS, __('Product added to wish-list!')));
                await this._syncWishlistWithBE(dispatch, wishlistId);
            }
        } catch {
            dispatch(showNotification(NotificationType.ERROR, __('Error updating wish list!')));
        } finally {
            dispatch(updateIsLoading(false));
        }
    }

    updateWishlistItem(
        dispatch: Dispatch,
        options: { wishlistItems: GQLWishlistItemUpdateInput[]; wishlistId: string },
    ): Promise<void> {
        if (!isSignedIn()) {
            return Promise.reject();
        }

        const { wishlistItems = [], wishlistId = '' } = options;

        return fetchMutation(WishlistQuery.updateProductsInWishlist(wishlistId, wishlistItems)).then(
            /** @namespace Store/Wishlist/Dispatcher/WishlistDispatcher/updateWishlistItem/fetchMutation/then */
            () => {
                this._syncWishlistWithBE(dispatch, wishlistId);
            },
        );
    }

    clearWishlist(dispatch: Dispatch): Promise<ClearWishlistAction | ShowNotificationAction> {
        if (!isSignedIn()) {
            return Promise.reject();
        }

        if (isAmWishlistEnabled()) {
            return fetchMutation<'clearWishlist', boolean>(AmMWishlistQuery.getClearWishlist(Number(BrowserDatabase.getItem('AmastyWishlist'))))
                .then(
                /** @namespace Store/Wishlist/Dispatcher/clearWishlistFetchMutationThen */
                    () => dispatch(clearWishlist()),
                )
                .catch(
                /** @namespace Store/Wishlist/Dispatcher/clearWishlistFetchMutationThenCatch */
                    () => dispatch(showNotification(NotificationType.ERROR, __('Error clearing wish list!'))),
                );
        }

        return fetchMutation<'clearWishlist', boolean>(WishlistQuery.getClearWishlist())
            .then(
                /** @namespace Store/Wishlist/Dispatcher/WishlistDispatcher/clearWishlist/then/catch/fetchMutation/then/dispatch */
                () => dispatch(clearWishlist()),
            )
            .catch(
                /** @namespace Store/Wishlist/Dispatcher/WishlistDispatcher/clearWishlist/then/catch/dispatch */
                () => dispatch(showNotification(NotificationType.ERROR, __('Error clearing wish list!'))),
            );
    }

    async moveWishlistToCart(dispatch: Dispatch, options: AmMWishListQueryOptions): Promise<void> {
        if (!isSignedIn()) {
            await Promise.reject();
        }

        const { sharingCode = '', wishlistId = 0 } = options;

        try {
            await fetchMutation<'moveWishlistToCart', boolean>(WishlistQuery.getMoveWishlistToCart(sharingCode, wishlistId));
        } finally {
            // await this._syncWishlistWithBE(dispatch);
            CartDispatcher.updateInitialCartData(dispatch, !!getAuthorizationToken());
            // dispatch(showNotification(NotificationType.SUCCESS, __('Alle product(en) zijn toegevoegd aan uw winkelwagen.')));
        }
    }

    async removeItemFromWishlist(
        dispatch: Dispatch,
        { item_id, noMessages, wishlistId }: { item_id: string; noMessages?: boolean; wishlistId?: string },
    ): Promise<void> {
        if (!item_id || !isSignedIn() || !wishlistId) {
            return Promise.reject();
        }

        dispatch(updateIsLoading(true));

        try {
            await fetchMutation(AmMWishlistQuery.getRemoveProductFromWishlistMutation(Number(item_id), Number(wishlistId)));
        } catch (e) {
            if (!noMessages) {
                dispatch(showNotification(NotificationType.ERROR, __('Error updating wish list!')));
            }

            return Promise.reject();
        }

        dispatch(removeItemFromWishlist(item_id));

        if (!noMessages) {
            // dispatch(showNotification(NotificationType.SUCCESS, __('Product has been removed from your Wish List!')));
        }

        return Promise.resolve();
    }

    // TODO: Need to make it in one request
    removeItemsFromWishlist(dispatch: Dispatch, itemIdMap: string[]): Promise<never> | Promise<void>[] {
        if (!itemIdMap.length || !isSignedIn()) {
            return Promise.reject();
        }

        return itemIdMap.map((id) => (
            fetchMutation<'removeProductFromWishlist', boolean>(
                WishlistQuery.getRemoveProductFromWishlistMutation(id),
            ).then(
                /** @namespace Store/Wishlist/Dispatcher/WishlistDispatcher/removeItemsFromWishlist/itemIdMap/map/fetchMutation/then */
                () => {
                    dispatch(removeItemFromWishlist(id));
                    // dispatch(showNotification(
                    //     NotificationType.SUCCESS,
                    //     __('Product has been removed from your Wish List!'),
                    // ));
                },
                /** @namespace Store/Wishlist/Dispatcher/WishlistDispatcher/removeItemsFromWishlist/itemIdMap/map/fetchMutation/then/catch */
                (error) => {
                    dispatch(showNotification(
                        NotificationType.ERROR,
                        getErrorMessage(error, __('Error updating wishlist!')),
                    ));
                },
            )
        ));
    }

    resetWishlist(dispatch: Dispatch): void {
        dispatch(clearWishlist());
    }
}

export default new WishlistDispatcher();
