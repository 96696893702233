/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

export const GTM_EVENT_KEY_GENERAL = 'general';
// vvv Event/plugin "notFound"
export const GTM_EVENT_KEY_NOT_FOUND = 'not_found';
// vvv Event/plugin "user"
export const GTM_EVENT_KEY_USER_LOGIN = 'login';
export const GTM_EVENT_KEY_USER_LOGIN_ATTEMPT = 'login_attempt';
export const GTM_EVENT_KEY_USER_REGISTER = 'sign_up';
export const GTM_EVENT_KEY_USER_REGISTER_ATTEMPT = 'register_attempt';
export const GTM_EVENT_KEY_USER_LOGOUT = 'logout';
// vvv Event/plugin "NewsletterSubscription"
export const GTM_EVENT_KEY_GENERATE_LEAD = 'generate_lead';
// vvv Event/plugin "search"
export const GTM_EVENT_KEY_SEARCH = 'search';
export const GTM_EVENT_KEY_SEARCH_STARTED = 'site_search_started';
// vvv Event/plugin "list"
export const GTM_EVENT_KEY_IMPRESSIONS = 'view_item_list';
export const GTM_EVENT_KEY_PRODUCT_CLICK = 'select_item';
// vvv Event/plugin "product"
export const GTM_EVENT_KEY_PRODUCT_DETAIL = 'view_item';
// vvv Event/plugin "cart"
export const GTM_EVENT_KEY_ADD_TO_CART = 'add_to_cart';
export const GTM_EVENT_KEY_REMOVE_FROM_CART = 'remove_from_cart';
export const GTM_EVENT_KEY_VIEW_CART = 'view_cart';
// vvv Event/plugin "checkout"
export const GTM_EVENT_KEY_CHECKOUT = 'begin_checkout';
export const GTM_EVENT_KEY_CHECKOUT_OPTION = 'checkoutOption';
// vvv Event/plugin "purchase"
export const GTM_EVENT_KEY_PURCHASE = 'purchase';
// vvv Event/plugin "sort"
export const GTM_EVENT_KEY_SORT = 'sort_select';
// vvv Event/plugin "wishlist"
export const GTM_EVENT_KEY_ADD_TO_WISHLIST = 'add_to_wishlist';
export const GTM_EVENT_KEY_REMOVE_FROM_WISHLIST = 'remove_from_wishlist';
export const GTM_EVENT_KEY_REMOVE_ALL_ITEMS_FROM_WISHLIST = 'remove_all_items_from_wishlist';
// vvv Event/plugin "errorMessage"
export const GTM_EVENT_KEY_ERROR_MESSAGE = 'error_message';
// vvv Event/plugin "pageReload"
export const GTM_EVENT_KEY_PAGE_RELOAD = 'performance';
// vvv Event/plugin "CmsPage"
export const GTM_EVENT_KEY_VIEW_PROMOTION = 'view_promotion';
export const GTM_EVENT_KEY_SELECT_PROMOTION = 'select_promotion';
// vvv Event/plugin "filter"
export const GTM_EVENT_KEY_FILTER_APPLIED = 'filter_select';
export const GTM_EVENT_KEY_FILTER_REMOVED = 'remove_filter_select';
export const GTM_EVENT_KEY_ALL_FILTERS_REMOVED = 'all_filters_removed';
// vvv No plugin here needed, we fire the event with the purchase event
export const GTM_EVENT_KEY_ADD_SHIPPING_INFO = 'add_shipping_info';
export const GTM_EVENT_KEY_ADD_PAYMENT_INFO = 'add_payment_info';

export const GTM_EVENT_KEY_FIREREMARKETING = 'fireRemarketingTag';
export const GTM_EVENT_KEY_ENHANCED_CONVERSION_DATA = 'enhanced_conversion_data';
