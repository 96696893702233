import { INTERVAL_OF_CHECK } from './Config';

/** @namespace Util/Request/LowPriorityLoad/waitForPriorityLoad */
export const waitForPriorityLoad = () => new Promise((resolve) => {
    function waitForIt(){
        if (window.isPriorityLoaded) {
            resolve(null);

            return;
        }

        setTimeout(waitForIt, INTERVAL_OF_CHECK);
    }

    waitForIt();
});

/** @namespace Util/Request/LowPriorityLoad/setLoadedFlag */
export const setLoadedFlag = () => {
    window.isPriorityLoaded = true;
};
