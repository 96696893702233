export enum AccountPageUrl {
    CONFIRMATION_URL = '/customer/account/confirmation',
    INFORMATION_EDIT_URL = '/customer/account/edit',
    FORGOT_PASSWORD_URL = '/customer/account/forgotpassword',
    REGISTRATION_URL = '/customer/account/create',
    LOGIN_URL = '/customer/account/login',
    ACCOUNT_URL = '/customer/account',
    ORDER_URL = '/sales/order/view/order_id',
    ORDER_PRINT_URL = '/sales/order/print/order_id',
    ORDER_HISTORY = '/sales/order/history',
    ADDRESS_NEW_URL = '/customer/address/new',
    ADDRESS_EDIT_URL = '/customer/address/edit/id',
}

export const CUSTOMER = 'customer';

// eslint-disable-next-line max-len
export const LOCKED_ACCOUNT_ERROR_MESSAGE = __('The account sign-in was incorrect or your account is disabled temporarily. Please wait and try again later.');
