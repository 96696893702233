import { TierPrice } from 'Query/ProductList.type';
import { GQLCurrencyEnum } from 'Type/Graphql.type';
import BrowserDatabase from 'Util/BrowserDatabase';

import { CurrencyMap, HUNDRED_PERCENT } from './Price.config';

/** @namespace Util/Price/formatCurrency */
export const formatCurrency = (
    currency = GQLCurrencyEnum.USD,
): string | null => (Object.keys(CurrencyMap).includes(currency) ? currency : null);

export const formatPriceWithSymbol = (price: number, currency = GQLCurrencyEnum.USD) : string => {
    const language = navigator.languages ? navigator.languages[0] : navigator.language;

    return new Intl.NumberFormat(language, { style: 'currency', currency }).format(price || 0);
};

/** @namespace Util/Price/formatPrice */
export const formatPrice = (price: number, currency = GQLCurrencyEnum.USD) : string => {
    const { storeConfig }: { storeConfig: any } = BrowserDatabase.getItem('config') || { storeConfig: {} };
    const defaultCountry = storeConfig?.default_country;
    const formattedPrice = new Intl.NumberFormat(defaultCountry, { minimumFractionDigits: 2, maximumFractionDigits: 2, currency }).format(price || 0);

    if (formattedPrice.slice(-2) === '00') {
        return `${formattedPrice.substring(0, formattedPrice.length - 3)},-`;
    }

    // Return the formatted price
    return formattedPrice;
};

/**
 * Calculate final price
 * @param {Number} discount discount percentage
 * @param {Number} min minimum price
 * @param {Number} reg regular price
 * @return {Number} final price
 * @namespace Util/Price/calculateFinalPrice
 */
export const calculateFinalPrice = (discount: number, min: number, reg: number): number => (discount ? min : reg);

/**
 * Calculate final price
 * @param {Number} tier tier price
 * @param {Number} spec special price
 * @return {Number} final discount
 * @namespace Util/Price/calculateTierDiscountOverSpecialPrice */
export const calculateTierDiscountOverSpecialPrice = (spec: number, tier: number): number => (
    Math.round(HUNDRED_PERCENT - (tier / spec) * HUNDRED_PERCENT)
);

/**
 * Calculate final price
 * @param {Number} price
 * @return {Number} price rounded to 2 digits
 * @namespace Util/Price/roundPrice
 */
export const roundPrice = (price: number): string => price.toFixed(2);

/** @namespace Util/Price/getLowestPriceTiersPrice */
export const getLowestPriceTiersPrice = (price_tiers: TierPrice[], currency: GQLCurrencyEnum): string => {
    const lowestValue = price_tiers
        .reduce((acc, { final_price: { value } }) => (acc < value ? acc : value), price_tiers[0].final_price.value);

    return formatPrice(lowestValue, currency);
};
