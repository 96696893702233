import { getAuthorizationToken } from './Token';

/** @namespace Util/Auth/IsSignedIn/isSignedIn */

export const isSignedIn = (): boolean => {
    const hasAuthToken = !!getAuthorizationToken();

    if (!hasAuthToken) {
        import('./LogoutIfSignedIn').then(
            /** @namespace Util/Auth/IsSignedIn/isSignedIn/then */
            ({ logoutIfSignedIn }) => {
                logoutIfSignedIn();
            },
        );
    }

    return hasAuthToken;
};

/** @namespace Util/Auth/IsSignedIn/isInitiallySignedIn */
export const isInitiallySignedIn = (): boolean => !!getAuthorizationToken();
