import { getPaymentData } from '../util/PaymentDataPersistence';

const updateInitialCartData = async (args, callback, instance) => {
    const paymentData = getPaymentData();

    if (!paymentData?.status && location.pathname.indexOf('mollie/checkout/response') === -1) {
        return callback(...args);
    }
}

export default {
    'Store/Cart/Dispatcher': {
        'member-function': {
            updateInitialCartData
        }
    }
};
