import {
    CheckoutSteps,
} from 'Route/Checkout/Checkout.config';
import { GQLCountryCodeEnum } from 'Type/Graphql.type';

import {
    CheckoutActionType,
    ClearAddressDataAction,
    UpdateAddressDataAction,
    UpdateEmailAction,
    UpdateEmailAvailableAction,
    UpdateShippingFieldsAction,
    ClearBillingAddressDataAction,
} from './Checkout.type';

/** @namespace Store/Checkout/Action/updateShippingFields */
export const updateShippingFields = (shippingFields: Record<string, unknown>): UpdateShippingFieldsAction => ({
    type: CheckoutActionType.UPDATE_SHIPPING_FIELDS,
    shippingFields,
});

/** @namespace Store/Checkout/Action/updateEmail */
export const updateEmail = (email: string): UpdateEmailAction => ({
    type: CheckoutActionType.UPDATE_EMAIL,
    email,
});

/** @namespace Store/Checkout/Action/updateEmailAvailable */
export const updateEmailAvailable = (isEmailAvailable: boolean): UpdateEmailAvailableAction => ({
    type: CheckoutActionType.UPDATE_EMAIL_AVAILABLE,
    isEmailAvailable,
});

export const updateAddressData = (result: any, addressType: GQLCountryCodeEnum, type: CheckoutSteps): UpdateAddressDataAction => ({
    type: CheckoutActionType.UPDATE_POSTCODE_ADDRESS_DATA,
    result,
    addressType,
    formType: type,
});

export const clearAddressData = (): ClearAddressDataAction => ({
    type: CheckoutActionType.CLEAR_POSTCODE_ADDRESS_DATA,
});

export const clearBillingAddressData = (): ClearBillingAddressDataAction => ({
    type: CheckoutActionType.CLEAR_POSTCODE_BILLING_ADDRESS_DATA,
});
