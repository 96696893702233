export enum Page {
    PDP = 'pdp',
    POPUP = 'popup',
    CATEGORY = 'category',
    CUSTOMER_ACCOUNT = 'customer_account',
    CUSTOMER_SUB_ACCOUNT = 'customer_sub_account',
    CUSTOMER_ACCOUNT_PAGE = 'customer_account_page',
    CUSTOMER_WISHLIST = 'wishlist',
    CUSTOMER_ORDER = 'customer_order',
    HOME_PAGE = 'home',
    MENU = 'menu',
    MENU_SUBCATEGORY = 'menu_subcategory',
    SEARCH = 'search',
    FILTER = 'filter',
    CART = 'cart',
    CART_OVERLAY = 'cart_overlay',
    CART_EDITING = 'cart_editing',
    CHECKOUT = 'checkout',
    CHECKOUT_SUCCESS = 'checkout_success',
    CHECKOUT_ACCOUNT = 'checkout_account',
    CMS_PAGE = 'cms-page',
    MY_ACCOUNT = 'my-account',
    NO_MATCH = 'no-match',
    CONTACT_US = 'contact-us',
    PRODUCT_COMPARE = 'product-compare',
    MENU_OVERLAY = 'menu_overlay',
    LOGINDROPDOWN_OVERLAY = 'logindropdown_overlay',
    REVIEW_POPUP = 'review_popup',
    WISHLIST_POPUP = 'wishlist_popup',
    NEWSLETTER_POPUP = 'NewsletterPopup',
    FAVORITE_POPUP = 'FavoritePopup',
    LEASE_POPUP = 'LeasePopup',
    OFFER_PAGE = 'offer_page',
    STORE_STOCK_POPUP = 'StoreStockPopup',
}

export enum OnSignInActionState {
    ProductSubscribe_SignIn = 'ProductSubscribe_SignIn',
    ProductSubscribe = 'ProductSubscribe',
    ProductWishList = 'ProductWishList',
    ProductReviewPopup = 'ProductReviewPopup',
}
