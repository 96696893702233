import { AnyAction } from 'redux';

export enum NoMatchActionType {
    UPDATE_NOMATCH = 'UPDATE_NOMATCH',
}

export interface UpdateNoMatchAction extends AnyAction {
    type: NoMatchActionType.UPDATE_NOMATCH;
    noMatch: boolean;
}

export interface NoMatchStore {
    noMatch: boolean;
}

declare module 'Util/Store/Store.type' {
    export interface RootState {
        NoMatchReducer: NoMatchStore;
    }
}
