import { AnyAction } from 'redux';

import { ProductsQueryOutput } from 'Query/ProductList.type';
import { IndexedProduct } from 'Util/Product/Product.type';

export enum LinkedProductType {
    UPSELL = 'upsell',
    RELATED = 'related',
    CROSS_SELL = 'crosssell',
    ASSOCIATED = 'associated',
    EXTRA_ACCESSORIES = 'extra_accessories',
}

export interface LinkedProducts {
    items: IndexedProduct[];
    total_count: number;
}

export type LinkedProductsMap = Partial<Record<LinkedProductType, Partial<LinkedProducts>>>;

export enum LinkedProductsActionType {
    UPDATE_LINKED_PRODUCTS = 'UPDATE_LINKED_PRODUCTS',
}

export interface UpdateLinkedProductsAction extends AnyAction {
    type: LinkedProductsActionType.UPDATE_LINKED_PRODUCTS;
    linkedProducts?: LinkedProductsMap & {
        updateCrossSell?: boolean;
    };
}

export interface LinkedProductsStore {
    linkedProducts: LinkedProductsMap;
}

declare module 'Util/Store/Store.type' {
    export interface RootState {
        LinkedProductsReducer: LinkedProductsStore;
    }
}

export interface LinkedProductsDispatcherData {
    products: ProductsQueryOutput;
}
