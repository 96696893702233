import { ProductListOptions } from 'Query/ProductList.type';
import ProductDispatcher from 'Store/Product/Product.dispatcher';
import getStore from 'Util/Store';

/** @namespace Util/Preload/ProductPreload */
export class ProductPreload {
    options: Partial<ProductListOptions> = {
        isSingleProduct: true,
        args: { filter: { productID: window.actionName?.id } },
    };

    preloadProduct() {
        ProductDispatcher.handleData(getStore().dispatch, this.options);
    }
}

export default new ProductPreload();
