import { appendWithStoreCode } from 'Util/Url';

export enum CheckoutSteps {
    SHIPPING_STEP = 'SHIPPING_STEP',
    BILLING_STEP = 'BILLING_STEP',
    DETAILS_STEP = 'DETAILS_STEP',
}

export enum CheckoutUrlSteps {
    BILLING_URL_STEP = 'billing',
    SHIPPING_URL_STEP = 'shipping',
    DETAILS_URL_STEP = 'success',
}

export enum CheckoutStepUrl {
    CHECKOUT_URL = '/onestepcheckout',
    BILLING_URL = '/onestepcheckout',
    SHIPPING_URL = '/onestepcheckout',
}

export const CHECKOUT_URL_REGEX = new RegExp(`^(${appendWithStoreCode('')})?${CheckoutStepUrl.CHECKOUT_URL}(/)?$`);

export const PAYMENT_TOTALS = 'PAYMENT_TOTALS';

export const UPDATE_EMAIL_CHECK_FREQUENCY = 1500; // ms
export const UPDATE_SHIPPING_COST_ESTIMATES_FREQUENCY = 800; // ms

export const CHECKOUT_ACCOUNT_CREATE_SESSION = 'CHECKOUT_ACCOUNT_CREATE_SESSION';
export const CUSTOMER_ID = 'CUSTOMER_ID';

export const MAX_ITEMS_COUNT_FOR_SUMMARY_FALLBACK = 3;
