/* eslint-disable import/prefer-default-export */
import {
    LinkedProductsActionType,
    LinkedProductsMap,
    UpdateLinkedProductsAction,
} from './LinkedProducts.type';

/**
 * Update upsell products list (rewrite if already exists).
 * @param  {Array<String>} upsell List of products returned from fetch
 * @return {void}
 * @namespace Store/LinkedProducts/Action/updateLinkedProducts
 */
export const updateLinkedProducts = (linkedProducts: LinkedProductsMap & {
    updateCrossSell?: boolean;
}): UpdateLinkedProductsAction => ({
    type: LinkedProductsActionType.UPDATE_LINKED_PRODUCTS,
    linkedProducts,
});
