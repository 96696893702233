import { AnyAction } from 'redux';

import { GQLCountryCodeEnum } from 'Type/Graphql.type';

export enum CheckoutActionType {
    UPDATE_SHIPPING_FIELDS = 'UPDATE_SHIPPING_FIELDS',
    UPDATE_EMAIL = 'UPDATE_EMAIL',
    UPDATE_EMAIL_AVAILABLE = 'UPDATE_EMAIL_AVAILABLE',
    UPDATE_POSTCODE_ADDRESS_DATA = 'UPDATE_POSTCODE_ADDRESS_DATA',
    CLEAR_POSTCODE_ADDRESS_DATA = 'CLEAR_POSTCODE_ADDRESS_DATA',
    CLEAR_POSTCODE_BILLING_ADDRESS_DATA = 'CLEAR_POSTCODE_BILLING_ADDRESS_DATA',
}

export interface UpdateShippingFieldsAction extends AnyAction {
    type: CheckoutActionType.UPDATE_SHIPPING_FIELDS;
    shippingFields: Record<string, unknown>;
}
export interface UpdateEmailAction extends AnyAction {
    type: CheckoutActionType.UPDATE_EMAIL;
    email: string;
}
export interface UpdateEmailAvailableAction extends AnyAction {
    type: CheckoutActionType.UPDATE_EMAIL_AVAILABLE;
    isEmailAvailable: boolean;
}
export interface UpdateAddressDataAction extends AnyAction {
    type: CheckoutActionType.UPDATE_POSTCODE_ADDRESS_DATA;
    result: any;
    addressType: GQLCountryCodeEnum;
}
export interface ClearAddressDataAction extends AnyAction {
    type: CheckoutActionType.CLEAR_POSTCODE_ADDRESS_DATA;
}

export interface ClearBillingAddressDataAction extends AnyAction {
    type: CheckoutActionType.CLEAR_POSTCODE_BILLING_ADDRESS_DATA;
}

export type CheckoutAction = UpdateShippingFieldsAction
| UpdateEmailAction
| UpdateEmailAvailableAction
| UpdateAddressDataAction
| ClearAddressDataAction
| ClearBillingAddressDataAction;

export interface CheckoutStore {
    shippingFields: Record<string, unknown>;
    email: string;
    isEmailAvailable: boolean;
    sippingPostcodeAddressData?: any;
    billingPostcodeAddressData?: any;
    isPostCodeAddressData: boolean;
    isShippingPostCodeAddressData: boolean;
    isBillingPostCodeAddressData: boolean;
    isHouseNumberAdditionIncorrect: boolean;
    sippingAutoCompleteAddressData?: any;
    billingAutoCompleteAddressData?: any;
}

declare module 'Util/Store/Store.type' {
    export interface RootState {
        CheckoutReducer: CheckoutStore;
    }
}

export interface CheckoutDispatcherData {
    isEmailAvailable: {
        is_email_available: boolean;
    };
}
