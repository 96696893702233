import { AnyAction } from 'redux';

import { AmMWishlists } from 'Query/AmMWishlist.type';
import { Wishlist } from 'Query/Wishlist.type';

export enum AmMWishlistType {
    UPDATE_AM_M_WISHLIST = 'UPDATE_AM_M_WISHLIST',
    UPDATE_AM_M_WISHLISTS = 'UPDATE_AM_M_WISHLISTS',
    UPDATE_REVIEWS = 'UPDATE_REVIEWS',
    UPDATE_REVIEWS_LOAD_STATUS = 'UPDATE_REVIEWS_LOAD_STATUS',
}

export interface UpdateAmMWishlistAction extends AnyAction {
    type: AmMWishlistType.UPDATE_AM_M_WISHLIST;
}

export interface UpdateAmMWishlistsAction extends AnyAction {
    type: AmMWishlistType.UPDATE_AM_M_WISHLISTS;
}

export interface UpdateLoadStatusAction extends AnyAction {
    type: AmMWishlistType.UPDATE_REVIEWS_LOAD_STATUS;
}

export interface AmMWishListQueryOptions {
    sharingCode?: string;
    wishlistId?: number;
}

export interface AmMWishListsQueryOptions {
    activeTabIndex?: string;
    currentPage?: number;
}

export interface AmMWishlistStore {
    wishLists: AmMWishlists;
    wishList: Wishlist;
    isLoading: boolean;
}

export type AmMWishlistAction =
    | UpdateAmMWishlistAction
    | UpdateAmMWishlistsAction
    | UpdateLoadStatusAction;

declare module 'Util/Store/Store.type' {
    export interface RootState {
        AmMWishlistReducer: AmMWishlistStore;
    }
}
