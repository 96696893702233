import { AnyAction } from 'redux';

export enum LoginDialogType {
    SHOW_LOGIN_DIALOG = 'SHOW_LOGIN_DIALOG',
    HIDE_LOGIN_DIALOG = 'HIDE_LOGIN_DIALOG',
}

export interface ShowLoginDialogAction extends AnyAction {
    type: LoginDialogType.SHOW_LOGIN_DIALOG;
}

export interface HideLoginDialogAction extends AnyAction {
    type: LoginDialogType.HIDE_LOGIN_DIALOG;
}

export interface LoginDialogStore {
    isLoginDialogShow: boolean;
}
export type ShowHideDialogAction =
    | ShowLoginDialogAction
    | HideLoginDialogAction;

declare module 'Util/Store/Store.type' {
    export interface RootState {
        LoginDialogReducer: LoginDialogStore;
    }
}
