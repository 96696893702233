/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

// import BrowserDatabase from 'Util/BrowserDatabase';
import MyAccountDispatcher from 'Store/MyAccount/MyAccount.dispatcher';
import { isSignedIn } from 'Util/Auth/IsSignedIn';
import getStore from 'Util/Store';

import { waitForCallback } from '../util/wait';
import { DL_VAL_NOT_APPLICABLE } from './common';

export const DL_VAL_USER_LOGIN = 'Logged in';
export const DL_VAL_USER_LOGOUT = 'Logged out';
// ! vvv this is hard code and depends on the value from backend
export const DL_VAL_EXISTING_CUSTOMER = 'Yes';
export const USER_EXISTING_CUSTOMER = 'Existing';
export const NEW_CUSTOMER = 'New';

/** @namespace Scandiweb/Gtm/Data/Customer/getCustomerData */
export const getCustomerData = async () => {
    if (!isSignedIn()) {
        // user is not signed in => return default object
        return {
            visitorLoginState: DL_VAL_USER_LOGOUT,
            visitorId: null,
            visitorExistingCustomer: DL_VAL_NOT_APPLICABLE,
            visitorLifetimeValue: 0,
            visitorLifetimeOrders: 0,
        };
    }

    const { dispatch } = getStore();

    // vvv Get customerData if customer id is undefined and customer is logged in
    if (!getStore().getState().MyAccountReducer?.customer?.id) {
        await MyAccountDispatcher.requestCustomerData(dispatch);
    }

    // vvv Wait for customer id, if customer is logged in
    await waitForCallback(() => getStore().getState().MyAccountReducer?.customer?.id);

    const {
        MyAccountReducer: {
            customer: {
                id,
                // email,
                userExistingCustomer,
                userLifetimeValue,
                userLifetimeOrders,
            } = {},
        } = {},
    } = getStore().getState();

    return {
        visitorLoginState: DL_VAL_USER_LOGIN,
        visitorId: id || 0,
        // vvv this fields are added via plugins
        visitorExistingCustomer: userExistingCustomer || DL_VAL_NOT_APPLICABLE,
        visitorLifetimeValue: userLifetimeValue || 0,
        visitorLifetimeOrders: userLifetimeOrders || 0,
    };
};
