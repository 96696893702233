export enum ProductType {
    CONFIGURABLE = 'configurable',
    GROUPED = 'grouped',
    SIMPLE = 'simple',
    BUNDLE = 'bundle',
    DOWNLOADABLE = 'downloadable',
    VIRTUAL = 'virtual',
}

export const TOOLTIP_CMSBLOCK_MAP = [
    {
        id: 'delivery_24_48_ur',
        time: '24 - 48 uur',
    },
    {
        id: 'delivery_1_2_weken',
        time: '1 - 2 weken',
    },
    {
        id: 'delivery_2_3_weken',
        time: '2 - 3 weken',
    },
    {
        id: 'delivery_3_4_weken',
        time: '3 - 4 weken',
    },
    {
        id: 'delivery_2_4_werkdagen',
        time: '2 - 4 werkdagen',
    },
    {
        id: 'delivery_5_7_werkdagen',
        time: '5 - 7 werkdagen',
    },
    {
        id: 'delivery_voor_15_uur_besteld',
        time: 'Voor 15:00 uur besteld, morgen in huis',
    },
];

export const ALLOWED_STOCK_IMPORTER = [
    'bartscher',
    'combisteel',
    'domest',
    'eigen import',
    'emga',
    'gastronoble',
    'hendi',
    'liebherr',
    'saro',
    'wegter',
    'fabrinox',
];
