import BrowserDatabase from 'Util/BrowserDatabase';

export const KEY = 'mollie_payment_data';
export const ONE_HOUR_IN_SECONDS = 3600;

/** @namespace Mollie/Scandipwa/Util/PaymentDataPersistence/setPaymentData */
export const setPaymentData = (paymentData) => {
    BrowserDatabase.setItem(paymentData, KEY, ONE_HOUR_IN_SECONDS);
};

/** @namespace Mollie/Scandipwa/Util/PaymentDataPersistence/getPaymentData */
export const getPaymentData = () => {
    const paymentData = BrowserDatabase.getItem(KEY);

    if (!paymentData || !paymentData.status) {
        return false;
    }

    return paymentData;
};

/** @namespace Mollie/Scandipwa/Util/PaymentDataPersistence/deletePaymentData */
export const deletePaymentData = () => {
    BrowserDatabase.deleteItem(KEY);
};
