/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Field } from '@tilework/opus';

/** @namespace Scandiweb/Gtm/Query/RegularPrice/Query */
export class RegularPriceQuery {
    getRegularPriceField(productID) {
        const field = new Field('getRegularPrice')
            .setAlias('getRegularPrice')
            .addArgument('productID', 'String!', productID)
            .addFieldList(this.getRegularPrice());

        return field;
    }

    getRegularPrice() {
        return [
            new Field('price'),
        ];
    }
}
export default new RegularPriceQuery();
