import {
    HideActiveOverlayAction,
    HideActivePopupAction,
    OverlayActionType,
    ToggleOverlayByKeyAction,
} from './Overlay.type';

/** @namespace Store/Overlay/Action/toggleOverlayByKey */
export const toggleOverlayByKey = (overlayKey: string, actionState: any = {}): ToggleOverlayByKeyAction => ({
    type: OverlayActionType.TOGGLE_OVERLAY,
    overlayKey,
    actionState,
});

/** @namespace Store/Overlay/Action/hideActiveOverlay */
export const hideActiveOverlay = (): HideActiveOverlayAction => ({
    type: OverlayActionType.HIDE_ACTIVE_OVERLAY,
});

/** @namespace Store/Overlay/Action/hideActivePopup */
export const hideActivePopup = (shouldPopupClose = true): HideActivePopupAction => ({
    type: OverlayActionType.HIDE_ACTIVE_POPUP,
    payload: shouldPopupClose,
});
