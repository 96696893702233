export enum MyAccountPageState {
    STATE_SIGN_IN = 'signIn',
    STATE_FORGOT_PASSWORD = 'forgotPassword',
    STATE_FORGOT_PASSWORD_SUCCESS = 'forgotPasswordSuccess',
    STATE_CREATE_ACCOUNT = 'createAccount',
    STATE_LOGGED_IN = 'loggedIn',
    STATE_CONFIRM_EMAIL = 'confirmEmail',
}

export const CUSTOMER_ACCOUNT_OVERLAY_KEY = 'customer_account';
export const CUSTOMER_DROPDOWN_MENU_OVERLAY_KEY = 'customer_dropdown_menu';
