export const ONE_MONTH_IN_SECONDS = 2592000;
export const FIVE_MINUTES_IN_SECONDS = 300;
export const DEFAULT_ERROR_MESSAGE = __('Something went wrong!');
export const EV_PRIORITY_LOAD_END = 'pr_end';
export const INTERVAL_OF_CHECK = 50; // ms
export const GRAPHQL_URI = '/graphql';
export const WINDOW_ID = 'WINDOW_ID';
export const HTTP_503_SERVICE_UNAVAILABLE = 503;
export const HTTP_410_GONE = 410;
export const HTTP_201_CREATED = 201;
