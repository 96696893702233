/* eslint-disable import/prefer-default-export */
/**
 * No-operate function.
 * Can be used as a fallback if real function wasn't passed.
 * In this case instead of creating empty function every time we can reuse existing.
 * Examples: default props, argument default values.
 * @namespace Util/Common/Index/noopFn
 */
export const noopFn = (): void => {};

/** @namespace Util/Common/Index/noopFnAsync */
export const noopFnAsync = async (): Promise<void> => {};

/** @namespace Util/Common/Index/decodeString */
export const decodeString = (string: string): string => {
    try {
        return decodeURIComponent(string);
    } catch (e) {
        return string;
    }
};

/** @namespace Util/Common/Index/spliceString */
export const spliceString = (originStr: string, idx: number, rem: number, str: string) => originStr.slice(0, idx) + str + originStr.slice(idx + Math.abs(rem));
