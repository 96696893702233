/* eslint-disable import/prefer-default-export */
import { NoMatchActionType, UpdateNoMatchAction } from './NoMatch.type';

/**
 * Update router to show NoMatch page
 * @param  {Boolean} noMatch New noMatch value
 * @return {void}
 * @namespace Store/NoMatch/Action/updateNoMatch
 */
export const updateNoMatch = (noMatch: boolean): UpdateNoMatchAction => ({
    type: NoMatchActionType.UPDATE_NOMATCH,
    noMatch,
});
