export enum ImageType {
    SMALL = 'small_image',
    THUMBNAIL = 'thumbnail',
    IMAGE = 'image',
}

export enum MediaType {
    IMAGE = 'image',
    VIDEO = 'external-video',
    PLACEHOLDER = 'placeholder',
}

export const MAX_ZOOM_SCALE = 8;

export const THUMBNAIL_KEY = ImageType.SMALL;
export const AMOUNT_OF_PLACEHOLDERS = 0;

export const PRODUCT_GALLERY_POPUP_ID = 'ProductGalleryPopup';
export const FAVORITE_BLOCK_POPUP_ID = 'FavoriteBlockPopup';
