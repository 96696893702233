/* eslint-disable import/prefer-default-export */
import { ProductItem } from 'Query/ProductList.type';

import { ProductActionType, UpdateProductDetailsAction } from './Product.type';

/**
 * Update product list with new list (rewrite if already exists).
 * @param  {Array<Object>} items List of products returned from fetch
 * @param  {Number} totalItems Total number of products in this filter
 * @return {void}
 * @namespace Store/Product/Action/updateProductDetails
 */
export const updateProductDetails = (product: ProductItem): UpdateProductDetailsAction => ({
    type: ProductActionType.UPDATE_PRODUCT_DETAILS,
    product,
});
