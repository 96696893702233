import { AmMWishlists } from 'Query/AmMWishlist.type';
import { Wishlist } from 'Query/Wishlist.type';

import {
    AmMWishlistType,
    UpdateAmMWishlistAction,
    UpdateAmMWishlistsAction,
    UpdateLoadStatusAction,
} from './AmMWishlist.type';

/** @namespace Store/AmMWishlist/Action/updateAmMWishlist */
export const updateAmMWishlist = (wishList: Wishlist): UpdateAmMWishlistAction => ({
    type: AmMWishlistType.UPDATE_AM_M_WISHLIST,
    wishList,
});

/** @namespace Store/AmMWishlist/Action/updateAmMWishlists */
export const updateAmMWishlists = (wishLists: AmMWishlists): UpdateAmMWishlistsAction => ({
    type: AmMWishlistType.UPDATE_AM_M_WISHLISTS,
    wishLists,
});

/** @namespace Store/AmMWishlist/Action/updateLoadStatus */
export const updateLoadStatus = (status: boolean): UpdateLoadStatusAction => ({
    type: AmMWishlistType.UPDATE_REVIEWS_LOAD_STATUS,
    isLoading: status,
});
