export const LOADING_TIME = 500;
export const LAYOUT_KEY = 'layout';
export const CAMPAIGN_PAGE_CAT_ID = 2222;

export enum CategoryDisplayMode {
    PRODUCTS = 'PRODUCTS',
    CMS_BLOCK = 'PAGE',
    BOTH = 'PRODUCTS_AND_PAGE',
}

export enum CategoryPageLayout {
    GRID = 'grid',
    LIST = 'list',
}

export enum CategoryPageProductCount {
    GRID_PRODUCT_COUNT = 36,
    LIST_PRODUCT_COUNT = 24,
}

export enum SortDirections {
    ASC = 'ASC',
    DESC = 'DESC',
}
