import {
    HideLoginDialogAction,
    LoginDialogType,
    ShowLoginDialogAction,
} from './LoginDialog.type';

/** @namespace Store/LoginDialog/Action/showLoginDialog */
export const showLoginDialog = (): ShowLoginDialogAction => ({
    type: LoginDialogType.SHOW_LOGIN_DIALOG,
});

/** @namespace Store/LoginDialog/Action/hideLoginDialog */
export const hideLoginDialog = (): HideLoginDialogAction => ({
    type: LoginDialogType.HIDE_LOGIN_DIALOG,
});
