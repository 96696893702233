export enum FieldType {
    MUTATION = 'mutation',
    QUERY = 'query',
}

export interface FieldArgument {
    name: string;
    type: string;
    value: unknown;
}

export interface PreparedRequest {
    query: string;
    variables: unknown;
}
