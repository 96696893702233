import { Page } from 'Component/Header/Header.config';
import { Wishlist } from 'Query/Wishlist.type';
import { showPopup } from 'Store/Popup/Popup.action';
import BrowserDatabase from 'Util/BrowserDatabase';
import getStore from 'Util/Store';
import { RootState } from 'Util/Store/Store.type';

export const PRODUCTS_IN_WISHLIST = 'wishlist_products';

/** @namespace Util/MultipleWishlist/isAmWishlistEnabled */
export const isAmWishlistEnabled = (): boolean => {
    const store = getStore();

    const {
        ConfigReducer: {
            amastyMWishlistConfig: {
                isEnabled = false,
            } = {},
        } = {},
    } = store.getState() as RootState;

    return isEnabled;
};

/** @namespace Util/MultipleWishlist/showWishlistsPopup */
export const showWishlistsPopup = (index = '', magentoProduct = {}): void => {
    const { dispatch } = getStore();

    dispatch(showPopup(Page.WISHLIST_POPUP + index, { magentoProduct, title: __('Kies je verlanglijst voor het geselecteerde product.') }));
};

/** @namespace Util/MultipleWishlist/getCompatibleWishlist */
export const getCompatibleWishlist = (wishlist: Wishlist) => {
    if (!Object.keys(wishlist).length) {
        return [];
    }

    const productsToAdd = wishlist.items.reduce((prev, wishlistItem) => {
        const {
            id,
            sku,
            product,
            description,
            qty: quantity,
            buy_request,
        } = wishlistItem;

        return {
            ...prev,
            [id]: {
                ...product,
                quantity,
                wishlist: {
                    id,
                    sku,
                    quantity,
                    description,
                    buy_request,
                },
            },
        };
    }, {});

    BrowserDatabase.setItem(productsToAdd, PRODUCTS_IN_WISHLIST);

    return productsToAdd;
};
