import { Field, Mutation, Query } from '@tilework/opus';

import WishlistQuery from 'Query/Wishlist.query';

import {
    AmMWishlistConfig,
    AmMWishlists,
    ItemType,
    SocialNetworksLinks,
} from './AmMWishlist.type';
import {
    Wishlist,
} from './Wishlist.type';

/** @namespace Query/AmMWishlist/Query */
export class AmMWishlistQuery {
    getConfigs(): Query<'amastyMWishlistConfig', AmMWishlistConfig> {
        const field = new Query<'amastyMWishlistConfig', AmMWishlistConfig>('amastyMWishlistConfig')
            .setAlias('amastyMWishlistConfig')
            .addFieldList(this.getConfigFields());

        return field;
    }

    getConfigFields(): Array<
    Field<'isEnabled', boolean>
    | Field<'socialNetworks', Array<string>>
    > {
        return [
            new Field<'isEnabled', boolean>('isEnabled'),
            new Field<'socialNetworks', Array<string>>('socialNetworks'),
        ];
    }

    createWishlist(wishlistName: string, type: string): Mutation<'createWishList', { status: string, message: string }> {
        return new Mutation<'createWishList', { status: string, message: string }>('createWishList')
            .setAlias('createWishList')
            .addArgument('wishlistName', 'String!', wishlistName)
            .addArgument('type', 'String!', type)
            .addField(new Field<'status', string>('status'))
            .addField(new Field<'message', string>('message'));
    }

    deleteWishlist(wishlistId: number): Mutation<'deleteWishList', { message: string }> {
        return new Mutation<'deleteWishList', { message: string }>('deleteWishList')
            .setAlias('deleteWishList')
            .addArgument('wishlistId', 'Int!', wishlistId)
            .addField(new Field<'message', string>('message'));
    }

    getWishlists(activeTab: string = 'wishlist', currentPage?: number): Query<'wishLists', AmMWishlists> {
        const field = new Query<'wishLists', AmMWishlists>('wishLists')
            .setAlias('wishLists')
            .addFieldList(this.getWishlistsFields());

        if (activeTab) {
            field.addArgument('activeTab', 'String', activeTab);
        }

        if (currentPage) {
            field.addArgument('currentPage', 'Int', currentPage);
        }

        return field;
    }

    getWishlistsFields(): Array<
    Field<'activeTab', string>
    | Field<'emptyMessage', string>
    | Field<'totalPages', string>
    | Field<'items', ItemType, true>
    > {
        return [
            new Field<'activeTab', string>('activeTab'),
            new Field<'emptyMessage', string>('emptyMessage'),
            new Field<'totalPages', string>('totalPages'),
            this.getItems(),
        ];
    }

    getItems(): Field<'items', ItemType, true> {
        return new Field<'items', ItemType, true>('items', true)
            .addFieldList(this.getWishlistsItemFields());
    }

    getWishlistsItemFields(): Array<
    Field<'wishlistId', number>
    | Field<'name', string>
    | Field<'sharingCode', string>
    | Field<'images', string[]>
    | Field<'viewUrl', string>
    | Field<'isDeleteable', boolean>
    | Field<'socialNetworksLinks', SocialNetworksLinks, true>
    > {
        return [
            new Field<'wishlistId', number>('wishlistId'),
            new Field<'name', string>('name'),
            new Field<'sharingCode', string>('sharingCode'),
            new Field<'images', string[]>('images'),
            new Field<'viewUrl', string>('viewUrl'),
            new Field<'isDeleteable', boolean>('isDeleteable'),
            this.getSocialNetworks(),
        ];
    }

    getSocialNetworks(): Field<'socialNetworksLinks', SocialNetworksLinks, true> {
        return new Field<'socialNetworksLinks', SocialNetworksLinks, true>('socialNetworksLinks', true)
            .addFieldList(this.getSocialNetworksFields());
    }

    getSocialNetworksFields(): Array<
    Field<'networkName', string>
    | Field<'url', string>
    > {
        return [
            new Field<'networkName', string>('networkName'),
            new Field<'url', string>('url'),
        ];
    }

    getWishlist(sharingCode?: string, wishlistId?: number): Query<'wishlist', Wishlist> {
        const field = new Query<'s_wishlist', Wishlist>('s_wishlist')
            .setAlias('wishlist')
            .addFieldList(WishlistQuery._getWishlistFields());

        if (sharingCode) {
            field.addArgument('sharing_code', 'ID', sharingCode);
        }

        if (wishlistId) {
            field.addArgument('wishlistId', 'Int', wishlistId);
        }

        return field;
    }

    getRemoveProductFromWishlistMutation(item_id: number, wishlistId: number): Mutation<'removeProductFromWishlist', boolean> {
        return new Mutation<'s_removeProductFromWishlist', boolean>('s_removeProductFromWishlist')
            .setAlias('removeProductFromWishlist')
            .addArgument('itemId', 'ID!', item_id)
            .addArgument('wishlistId', 'Int', wishlistId);
    }

    getClearWishlist(wishlistId: number): Mutation<'clearWishlist', boolean> {
        return new Mutation<'s_clearWishlist', boolean>('s_clearWishlist')
            .setAlias('clearWishlist')
            .addArgument('wishlistId', 'Int', wishlistId);
    }

    updateWishlistName(wishlistId: number, name: string): Mutation<'updateWishlistName', boolean> {
        return new Mutation<'updateWishlistName', boolean>('updateWishlistName')
            .setAlias('updateWishlistName')
            .addArgument('wishlistId', 'Int!', wishlistId)
            .addArgument('name', 'String!', name);
    }
}

export default new AmMWishlistQuery();
